import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import navigate from '../utils/navigate'
import { Context as UserContext } from './UserContext'

export default function Header({ NoLogout }) {
  const data = useStaticQuery(graphql`
    query HeaderComponent {
      site {
        siteMetadata {
          pathPrefix
          home
        }
      }
    }
  `)
  const { pathPrefix, home } = data.site.siteMetadata

  const { logout } = useContext(UserContext)
  const handleLogout = (event) => {
    event.preventDefault();
    logout()
    navigate(home)
  }

  return (
    <div id="header_leave">
      <div id="headerlinks">
        <a
          href={`${pathPrefix}/Documents/FMLA Frequently Asked Questions.pdf`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <div id="icon_faq" align="left" valign="middle" />
          <span>FMLA FAQs</span>
        </a>
        <a
          href={`${pathPrefix}/Documents/FMLA Family and Medical Leave Act (FMLA) Employee Guide.pdf`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <div id="icon_benestatement" />
          <span>FMLA Guide</span>
        </a>
        {NoLogout ? null : (
          <a onClick={handleLogout} href="index.html">
            <div id="icon_logout" />
            <span>Logout</span>
          </a>
        )}
      </div>
    </div>
  );
}
