import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'


export default function Footer() {
  const data = useStaticQuery(graphql`
    query FooterComoponent {
      site {
        siteMetadata {
          phone {
            formatted
            plain
            hours
          }
          email
          chat {
            url
            hours
          }
        }
      }
    }
  `)
  const {
    phone,
    email,
    chat,
  } = data.site.siteMetadata

  return (
    <>
      <div className="footerWrapper">
        <div className="footerHomeContent">
          <div className="footerLeftDiv" style={{ float: 'none' }}>
            Copyright © 2018 CBC. All Rights Reserved.{' '}
            <span className="lightRed">|</span>{' '}
            <a href="https://www.cbcins.com/Privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            <br />
          </div>

          <div className="footerRightDiv" style={{ float: 'none' }}>
            <a target="_blank" rel="noopener noreferrer" href={`mailto:${email}`}>
              <div className="EmailFooterBTN">24-Hour Response M-F</div>
            </a>
            <a href={chat.url}>
              <div className="ChatFooterBTN">{chat.hours}</div>
            </a>
            <a href={`tel:${phone.plain}`}>
              <div style={{ width: 230 }} className="PhoneFooterBTN">
                Phone: {phone.formatted} {phone.hours}{' '}
              </div>
            </a>
          </div>
          <div className="footerLogo" />
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
          <p className="clear">&nbsp;</p>
        </div>
      </div>
    </>
  );
}
